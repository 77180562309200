body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

a {
  display: block;
}

p a {
  display: inline;
}

dl {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 5px 40px;
}

dl,
dt,
dd {
  margin: 0;
  padding: 0;
}

dt {
  font-weight: 500;
}

p,
a,
h1,
h2,
h3,
h4 {
  padding: 0;
  line-height: 1.2em;
  margin: 0;
}

h1 {
  font-size: 28px;
  font-weight: 600;
}

h2 {
  font-size: 22px;
  font-weight: 600;
}

h3 {
  font-weight: 600;
  font-size: 17px;
  border-bottom: 1px solid #ccc;
  padding: 0 0 5px;
}

label {
  font-weight: 700;
}

article {
  display: grid;
  gap: 15px;
}

hr {
  width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
}

button {
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.mt-default {
  margin-top: 40px;
}

.mt-medium {
  margin-top: 20px;
}

.mt-small {
  margin-top: 15px;
}